import React from "react"
import {Helmet} from 'react-helmet'
import SEO from '../components/seo'
import Link from "gatsby-link"
import { graphql } from 'gatsby'
import Layout from "../layouts/layout-scroll"
import Image from 'gatsby-image'
import MediaQuery from 'react-responsive'

export default ({data}) => {
    const post = data.markdownRemark
    const posts = (data.allMarkdownRemark) ? data.allMarkdownRemark.edges : []
    return (
      <Layout>
        <Helmet htmlAttributes={{ class:'collection' }} />
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        <div>
            <MediaQuery query="(min-device-width: 1224px)">
              <section style={{
                position: 'relative',
                height: '97vh',
              }} >
              <Image
                fluid={post.frontmatter.cover.childImageSharp.fluid}
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                }}
              />
              <div
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                background: 'linear-gradient(transparent, 70%, #3336)',
              }}
              />
              <div style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                height: '20vw',
                marginBottom: '-13vw',
                transform:'skew(0deg,-5deg)',
              }}
              className='collection-form'>
              &nbsp;
              </div>
              <div style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0 }}
                >
                <h1>{post.frontmatter.title}</h1>
                <p style={{ textAlign: 'center' }}>{post.frontmatter.subtitle}</p>
                </div>
                </section>
            </MediaQuery>
            <MediaQuery query="(max-device-width: 1224px)">
            <section style={{
              position: 'relative',
              height: '20rem',
            }} >
            <Image
              fluid={post.frontmatter.cover.childImageSharp.fluid}
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
              }}
            />
            <div
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              background: 'linear-gradient(transparent, 70%, #3336)',
            }}
            />
            <div style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              height: '20vw',
              marginBottom: '-13vw',
              transform:'skew(0deg,-5deg)',
            }}
            className='collection-form'>
            &nbsp;
            </div>
            <div style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0 }}
              >
                <h1>{post.frontmatter.title}</h1>
                <div style={{ textAlign: 'center' }}>{post.frontmatter.subtitle}</div>
              </div>
              </section>
            </MediaQuery>
            <section  style={{
              transform: 'skew(0deg, 0deg)',
              zIndex: 1,
            }} >
              <hr className="divider" style={{
                marginTop: '1em',
                marginBottom: '1.5em',
              }}/>
              <div
                dangerouslySetInnerHTML={{__html: post.html}}
              />
              <hr className="divider" style={{
                marginTop: '1em',
              }}/>
              <section style={{
                transform: 'skew(0deg, 0deg)',
                zIndex: 1,
              }}>
                <div style={{
                  maxWidth: '25em',
                  minHeight: '15rem',
                  margin: '0 auto',
                }}>
                  {posts.map(({node}) =>
                      <div
                          key={`${node.fields.slug}`}
                          style={{
                          }}
                      >
                          <Link
                              to={node.fields.slug}
                              style={{textDecoration: `none`, color: `inherit`}}
                          >
                              <h3 style={{
                                marginBottom: '0.5rem',
                                display: 'inline-block'
                              }}>
                                  {node.frontmatter.title}{" "}
                              </h3>
                              <span style={{
                                color: "#BBB"
                              }}
                              > — {node.frontmatter.date}</span>
                              <p>
                                  {node.excerpt}
                              </p>
                          </Link>
                      </div>
                )}
                </div>
              </section>
          </section>
        </div>
      </Layout>
    )
}

export const query = graphql`
  query BlogCollectionQuery($slug: String!, $tags: String!) {
    site {
      siteMetadata {
        title
        author
        descriptions {
          collections
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
        subtitle
        cover {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
            fixed {
              src
              srcSet
              width
              height
            }
          }
        }
      }
    },
    allMarkdownRemark(
      filter: {frontmatter: { type: { ne: "collection" }, visible: { eq: true}, tags: { in: [$tags]}}},
      sort: { fields: [frontmatter___date], order: ASC}
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "DD/MM")
          }
        }
      }
    }
  }
`
